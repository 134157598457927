import React, { useEffect, useState } from 'react';
import {
  Box, Button, TextField, Grid, Typography, Paper, Modal,
  Snackbar, Alert, IconButton, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, MenuItem, Stack, Breadcrumbs, Link, Autocomplete, Toolbar
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { NumericFormat } from 'react-number-format';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'; // Importa axios para a requisição à API
import { useNavigate } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';




import { BarChart, Bar, XAxis, YAxis, Tooltip as RechartTooltip, ResponsiveContainer } from 'recharts';

export default function ContasAPagar() {
  const [contas, setContas] = useState([]);
  const [fornecedores, setFornecedores] = useState([]);
  const [categorias, setCategorias] = useState([]); // Estado para armazenar as categorias
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [valor, setValor] = useState('');
  const [dataVencimento, setDataVencimento] = useState(null);
  const [dataPagamento, setDataPagamento] = useState(null);
  const [dataEmissao, setDataEmissao] = useState(null);
  const [historico, setHistorico] = useState('');
  const [status, setStatus] = useState('pendente');
  const [selectedFornecedor, setSelectedFornecedor] = useState(null);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [centrosDeCusto, setCentrosDeCusto] = useState([]); // Estado para armazenar os centros de custo
  const [selectedCentroCusto, setSelectedCentroCusto] = useState(null); // Define o estado para Centro de Custo
  const [contasBancarias, setContasBancarias] = useState([]); // Estado para armazenar as contas bancárias

  const [selectedContaEmpresa, setSelectedContaEmpresa] = useState('');
  const [numeroNotaFiscal, setNumeroNotaFiscal] = useState('');
const [numeroPedido, setNumeroPedido] = useState('');
const [parcelado, setParcelado] = useState('nao'); // 'nao' para não parcelado, 'sim' para parcelado
const [quantidadeParcelas, setQuantidadeParcelas] = useState('');
// Coloque isso no início do componente, junto com os outros useState
const todasAsColunas = [
  'id', 'fornecedor_id', 'categoria_id', 'valor', 'data_vencimento',
  'status', 'historico', 'data_pagamento', 'data_emissao',
  'numero_nota_fiscal', 'numero_pedido', 'parcelado', 'quantidade_parcelas'
];

const [colunasVisiveis, setColunasVisiveis] = useState(['id', 'fornecedor_id', 'valor', 'data_vencimento']);

const fetchColunas = () => {
  setColunasVisiveis(['Data', 'Fornecedor', 'Valor']); // Defina colunas visíveis padrão
};

// URL da API para categorias de transações
const categoriasApiUrl = 'https://athixdev.xyz/aabb/categorias_transacoes.php';
const fornecedoresApiUrl = 'https://athixdev.xyz/aabb/fornecedores.php';
const centrosDeCustoApiUrl = 'https://athixdev.xyz/aabb/centro_de_custo.php'; // URL para Centro de Custo
const contasBancariasApiUrl = 'https://athixdev.xyz/aabb/contas_bancarias.php'; // API para buscar contas bancárias

const handleToggleColuna = (coluna) => {
  setColunasVisiveis(prev => (
      prev.includes(coluna) ? prev.filter(c => c !== coluna) : [...prev, coluna]
  ));
};

const navigate = useNavigate();

const renderCheckboxes = () => (
  <div>
      {todasAsColunas.map(coluna => (
          <div key={coluna}>
              <Checkbox
                  checked={colunasVisiveis.includes(coluna)}
                  onChange={() => handleToggleColuna(coluna)}
              />
              <span>{coluna}</span>
          </div>
      ))}
  </div>
);
const fetchContas = async () => {
  try {
      const response = await axios.get('https://athixdev.xyz/aabb/contas_pagar.php');
      console.log("Dados recebidos do backend:", response.data); // Verifica o formato dos dados
      setContas(response.data);
  } catch (error) {
      console.error("Erro ao buscar contas a pagar:", error);
  }
};



const [editingId, setEditingId] = useState(null);

const handleSave = async () => {
  const payload = {
    fornecedor_id: selectedFornecedor?.id || null,
    categoria_id: selectedCategoria?.id || null,
    valor: parseFloat(valor) || 0,
    data_vencimento: dataVencimento ? dataVencimento.toISOString().split('T')[0] : null,
  data_pagamento: dataPagamento ? dataPagamento.toISOString().split('T')[0] : null,
  data_emissao: dataEmissao ? dataEmissao.toISOString().split('T')[0] : null,
    numero_nota_fiscal: numeroNotaFiscal || null,
    numero_pedido: numeroPedido || null,
    parcelado,
    quantidade_parcelas: parcelado === 'sim' ? parseInt(quantidadeParcelas, 10) : null,
    status,
    historico,
  };

  try {
    if (editingId) {
      await axios.put(`https://athixdev.xyz/aabb/contas_pagar.php`, {
        ...payload,
        id: editingId,
      });
      setSnackbarMessage('Conta atualizada com sucesso!');
    } else {
      await axios.post(`https://athixdev.xyz/aabb/contas_pagar.php`, payload);
      setSnackbarMessage('Conta adicionada com sucesso!');
    }

    setSnackbarOpen(true);
    setModalOpen(false);
    fetchContas();
    resetForm();
  } catch (error) {
    console.error("Erro ao salvar conta:", error);
    setSnackbarMessage('Erro ao salvar conta.');
    setSnackbarOpen(true);
  }
};



const formatDateToBR = (date) => {
  if (!date) return null;
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};


const displayDate = (dateStr) => {
  if (!dateStr) return '';
  const [year, month, day] = dateStr.split('-');
  return `${day}/${month}/${year}`;
};

// Função para resetar o formulário
const resetForm = () => {
  setEditingId(null);
  setSelectedFornecedor(null);
  setSelectedCategoria(null);
  setValor('');
  setDataVencimento(null);
  setDataPagamento(null);
  setDataEmissao(null);
  setHistorico('');
  setStatus('pendente');
  setNumeroNotaFiscal('');
  setNumeroPedido('');
  setParcelado('nao');
  setQuantidadeParcelas('');
};




  
  // Função para buscar as categorias da API
  const fetchCategorias = async () => {
    try {
      const response = await axios.get(categoriasApiUrl);
      setCategorias(response.data); // Armazena as categorias no estado
    } catch (error) {
      console.error("Erro ao buscar categorias:", error);
    }
  };

  const fetchContasBancarias = async () => {
    try {
      const response = await axios.get('https://athixdev.xyz/aabb/contas_bancarias.php');
      setContasBancarias(response.data); // Armazena as contas no estado
    } catch (error) {
      console.error("Erro ao buscar contas bancárias:", error);
    }
  };

  const fetchCentrosDeCusto = async () => {
    try {
      const response = await axios.get(centrosDeCustoApiUrl);
      setCentrosDeCusto(response.data);
    } catch (error) {
      console.error("Erro ao buscar centros de custo:", error);
    }
  };  
 

  const handleDelete = async (id) => {
    try {
        await axios.delete('https://athixdev.xyz/aabb/contas_pagar.php', { data: { id } });
        setSnackbarMessage('Conta removida com sucesso!');
        setSnackbarOpen(true);
        fetchContas();
    } catch (error) {
        console.error("Erro ao excluir conta:", error);
    }
};

const handleEdit = (conta) => {
  setEditingId(conta.id);

  // Buscar o fornecedor selecionado
  const fornecedorSelecionado = fornecedores.find(f => f.id === conta.fornecedor_id);
  if (fornecedorSelecionado) {
    setSelectedFornecedor(fornecedorSelecionado);
  } else {
    console.error("Fornecedor não encontrado para o ID:", conta.fornecedor_id);
  }

  // Buscar a categoria selecionada
  const categoriaSelecionada = categorias.find(c => c.id === conta.categoria_id);
  if (categoriaSelecionada) {
    setSelectedCategoria(categoriaSelecionada);
  } else {
    console.error("Categoria não encontrada para o ID:", conta.categoria_id);
  }

  setValor(conta.valor);
  setDataVencimento(conta.data_vencimento);
  setDataPagamento(conta.data_pagamento);
  setDataEmissao(conta.data_emissao);
  setHistorico(conta.historico);
  setStatus(conta.status);
  setNumeroNotaFiscal(conta.numero_nota_fiscal);
  setNumeroPedido(conta.numero_pedido);
  setParcelado(conta.parcelado);
  setQuantidadeParcelas(conta.quantidade_parcelas);
  setModalOpen(true);
};



useEffect(() => {
  fetchColunas();
  fetchContas();
  fetchFornecedores();
  fetchCategorias();
  fetchCentrosDeCusto();
  fetchContasBancarias();
}, []);
useEffect(() => {
  console.log("Estado `contas` atualizado:", contas);
}, [contas]);


   // Função para buscar fornecedores da API
   const fetchFornecedores = async () => {
    try {
      const response = await axios.get(fornecedoresApiUrl);
      setFornecedores(response.data);
      console.log("Fornecedores recebidos:", response.data); // Log para verificar os dados
    } catch (error) {
      console.error("Erro ao buscar fornecedores:", error);
    }
  };
  

  const data = [
    { name: 'Receitas', value: 86423.6 },
    { name: 'Despesas', value: 32587.2 },
  ];

  return (
    <Box sx={{ padding: 3 }}>

       {/* Breadcrumbs para navegação */}
 <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
        <Link underline="hover" color="inherit" onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}>
          Módulo Financeiro
        </Link>
        <Typography color="text.primary">Contas Bancárias</Typography>
      </Breadcrumbs>
      {/* Resumo de Balanço */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <Button variant="contained" color="success" onClick={() => setModalOpen(true)}>Adicionar</Button>
        <Stack direction="row" spacing={3} alignItems="center">
          <Box>
            <Typography variant="h6">Receitas</Typography>
            <Typography color="green" variant="h5">R$ 86.423,60</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Despesas</Typography>
            <Typography color="red" variant="h5">R$ 32.587,20</Typography>
          </Box>
          <Box>
            <Typography variant="h6">Total do período</Typography>
            <Typography color="primary" variant="h5">R$ 53.836,40</Typography>
          </Box>
        </Stack>
      </Box>

      {/* Gráfico de Balanço */}
      <ResponsiveContainer width="100%" height={200}>
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <YAxis />
          <RechartTooltip />
          <Bar dataKey="value" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>

      {/* Tabela de Contas Cadastradas */}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
  <Toolbar>
    <Typography variant="h6">Histórico de Contas</Typography>
  </Toolbar>
  <Table>
    <TableHead>
      <TableRow>
        {colunasVisiveis.map((coluna) => (
          <TableCell key={coluna}>{coluna}</TableCell>
        ))}
        <TableCell>Ações</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {contas.map((conta) => (
        <TableRow key={conta.id}>
          {colunasVisiveis.map((coluna) => (
            <TableCell key={coluna}>
              {conta[coluna] !== undefined && conta[coluna] !== null ? conta[coluna] : 'N/A'}
            </TableCell>
          ))}
          <TableCell>
            <IconButton color="primary" onClick={() => handleEdit(conta)}>
              <VisibilityIcon />
            </IconButton>
            <IconButton color="error" onClick={() => handleDelete(conta.id)}>
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>



      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
  <Paper
    sx={{
      width: '90%',
      maxWidth: 600,
      maxHeight: '90vh',
      overflow: 'auto',
      padding: 4,
      mx: 'auto',
      my: '5%',
      position: 'relative',
    }}
  >
    {/* Botão de Fechar (X) no canto superior direito */}
    <IconButton
      aria-label="close"
      onClick={() => setModalOpen(false)}
      sx={{ position: 'absolute', top: 8, right: 8 }}
    >
      <CloseIcon />
    </IconButton>
    <Typography variant="h6" mb={2}>
      Adicionar Conta
    </Typography>
    <Grid container spacing={2}>
      {/* Campo Fornecedor */}
      <Grid item xs={12}>
      <Autocomplete
  options={fornecedores} // Verifique se 'fornecedores' contém os dados esperados
  getOptionLabel={(option) => option.nome_razao_social} // Confirme que este campo existe nos dados retornados
  value={selectedFornecedor}
  onChange={(event, newValue) => setSelectedFornecedor(newValue)}
  renderInput={(params) => (
    <TextField {...params} label="Fornecedor" fullWidth />
  )}
/>

            </Grid>

      {/* Campo Categoria */}
      <Grid item xs={12}>
              <Autocomplete
                options={categorias} // Lista de categorias da API
                getOptionLabel={(option) => option.nome} // Define o campo 'nome' como o label
                value={selectedCategoria}
                onChange={(event, newValue) => setSelectedCategoria(newValue)} // Armazena o objeto da categoria selecionada
                renderInput={(params) => (
                  <TextField {...params} label="Categoria" fullWidth />
                )}
              />
            </Grid>


            <Grid item xs={12}>
              <Autocomplete
                options={centrosDeCusto}
                getOptionLabel={(option) => option.nome} // Define o campo 'nome' como o label
                value={selectedCentroCusto}
                onChange={(event, newValue) => setSelectedCentroCusto(newValue)} // Armazena o objeto do centro de custo selecionado
                renderInput={(params) => (
                  <TextField {...params} label="Centro de Custo" fullWidth />
                )}
              />
            </Grid>

            <Grid item xs={12}>
  <Autocomplete
    options={contasBancarias}
    getOptionLabel={(option) => option.banco || ''} // Define 'banco' como o label principal para exibir o nome do banco
    value={selectedContaEmpresa}
    onChange={(event, newValue) => setSelectedContaEmpresa(newValue)}
    renderInput={(params) => (
      <TextField {...params} label="Conta Bancária/Conta Empresa" fullWidth />
    )}
  />
</Grid>



      {/* Campos de Data na Mesma Linha */}
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data de Emissão"
            value={dataEmissao}
            onChange={(newValue) => setDataEmissao(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data de Vencimento"
            value={dataVencimento}
            onChange={(newValue) => setDataVencimento(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Data de Pagamento"
            value={dataPagamento}
            onChange={(newValue) => setDataPagamento(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>

      {/* Campo Status */}
     {/* Campo Status */}
<Grid item xs={12}>
  <TextField
    select
    fullWidth
    label="Status"
    value={status}
    onChange={(e) => setStatus(e.target.value)}
  >
    <MenuItem value="pendente">Pendente</MenuItem>
    <MenuItem value="pago">Pago</MenuItem>
    <MenuItem value="aguardando">Aguardando Aprovação</MenuItem>
    <MenuItem value="cancelado">Cancelado</MenuItem>
    <MenuItem value="processamento">Em Processamento</MenuItem>
    <MenuItem value="rejeitado">Rejeitado</MenuItem>
  </TextField>
</Grid>

      <Grid item xs={12}>
  <TextField
    fullWidth
    label="Número da Nota Fiscal"
    value={numeroNotaFiscal}
    onChange={(e) => setNumeroNotaFiscal(e.target.value)}
  />
</Grid>

<Grid item xs={12}>
  <TextField
    fullWidth
    label="Número do Pedido"
    value={numeroPedido}
    onChange={(e) => setNumeroPedido(e.target.value)}
  />
</Grid>
<Grid item xs={12}>
  <TextField
    label="Valor"
    fullWidth
    value={valor}
    onChange={(e) => setValor(e.target.value.replace(',', '.'))} // Substitui vírgula por ponto
    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} // Configurações de entrada numérica
  />
</Grid>

<Grid item xs={12}>
  <TextField
    select
    fullWidth
    label="Parcelado"
    value={parcelado}
    onChange={(e) => setParcelado(e.target.value)}
  >
    <MenuItem value="nao">Não</MenuItem>
    <MenuItem value="sim">Sim</MenuItem>
  </TextField>
</Grid>

{/* Campo Condicional para Quantidade de Parcelas */}
{parcelado === 'sim' && (
  <Grid item xs={12}>
    <TextField
      fullWidth
      label="Quantidade de Parcelas"
      value={quantidadeParcelas}
      onChange={(e) => setQuantidadeParcelas(e.target.value)}
      type="number" // Tipo numérico para permitir apenas números
      inputProps={{ min: 1 }} // Definir o mínimo como 1
    />
  </Grid>
)}



      {/* Botão Salvar */}
      <Grid item xs={12}>
        <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
          Salvar Conta
        </Button>
      </Grid>
    </Grid>
  </Paper>
</Modal>


      {/* Snackbar para Feedback */}
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
