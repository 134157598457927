import React, { useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  IconButton,
  Button,
  useMediaQuery,
  Container,
  Divider,
} from '@mui/material';
import {
  Category as CategoryIcon,
  Store as StoreIcon,
  Receipt as ReceiptIcon,
  AttachMoney as AttachMoneyIcon,
  AccountBalanceWallet as AccountBalanceWalletIcon,
  BusinessCenter as BusinessCenterIcon,
  TrendingUp as TrendingUpIcon,
  CreditCard as CreditCardIcon,
} from '@mui/icons-material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const financialModules = [
  {
    title: 'Categorias de Transações',
    description: 'Organize e classifique todas as receitas e despesas.',
    icon: <CategoryIcon fontSize="large" />,
    link: '/CategoriasTransacoes',
  },
  {
    title: 'Contas a Pagar',
    description: 'Acompanhe todas as despesas e pagamentos.',
    icon: <ReceiptIcon fontSize="large" />,
    link: '/contas-pagar',
  },
  {
    title: 'Contas a Receber',
    description: 'Controle todas as receitas e recebimentos pendentes.',
    icon: <AttachMoneyIcon fontSize="large" />,
    link: '/contas-receber',
  },
  {
    title: 'Fluxo de Caixa',
    description: 'Acompanhe entradas e saídas em tempo real.',
    icon: <AccountBalanceWalletIcon fontSize="large" />,
    link: '/fluxo-caixa',
  },
  {
    title: 'Folha de Pagamento',
    description: 'Gerencie dados e pagamentos de funcionários.',
    icon: <BusinessCenterIcon fontSize="large" />,
    link: '/folha-pagamento',
  },
  {
    title: 'Conta Bancária',
    description: 'Gerencie as contas bancárias da empresa.',
    icon: <TrendingUpIcon fontSize="large" />,
    link: '/conta-bancaria',
  },
  {
    title: 'Transações Financeiras',
    description: 'Monitore e registre todas as transações financeiras.',
    icon: <CreditCardIcon fontSize="large" />,
    link: '/transacoes-financeiras',
  },
  {
    title: 'Centro de Custo',
    description: 'Gerencie e categorize centros de custo para melhor controle financeiro.',
    icon: <StoreIcon fontSize="large" />,
    link: '/centro-custo',
  },
];

export default function ModuloFinanceiro() {
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: { main: '#1976d2' },
          background: { default: '#f4f6f8', paper: '#ffffff' },
        },
        typography: { fontFamily: 'Poppins, sans-serif' },
      }),
    []
  );

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          padding: 4,
          minHeight: '100vh',
          background: theme.palette.background.default,
          transition: 'padding 0.3s',
        }}
      >
        {/* Introdução */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
      

           {/* Títulos e Introdução */}
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
        Módulo Financeiro
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
        Gerencie as finanças da sua empresa com ferramentas robustas e práticas.
        </Typography>
      </Box>
        </motion.div>

        <Divider sx={{ marginY: 3 }} />

        {/* Módulos Financeiros */}
        <Grid container spacing={3}>
          {financialModules.map((module, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 3,
                    borderRadius: 2,
                    boxShadow: 3,
                    transition: 'transform 0.2s',
                    background: theme.palette.background.paper,
                    '&:hover': {
                      transform: 'scale(1.05)',
                      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                    },
                  }}
                >
                  <IconButton
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      color: '#fff',
                      marginBottom: 2,
                      '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                      },
                    }}
                  >
                    {module.icon}
                  </IconButton>
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }}>
                      {module.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                      {module.description}
                    </Typography>
                    <Button
                      component={Link}
                      to={module.link}
                      variant="contained"
                      color="primary"
                      fullWidth
                      sx={{ marginTop: 1 }}
                    >
                      Acessar
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
