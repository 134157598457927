import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  Divider,
  IconButton,
} from '@mui/material';
import {
  LineChart,
  BarChart,
  PieChart,
} from '@mui/x-charts';
import {
  AccountBalance as AccountBalanceIcon,
  TrendingUp as TrendingUpIcon,
  AttachMoney as AttachMoneyIcon,
  Email as EmailIcon,
  InsertChart as InsertChartIcon,
  TaskAlt as TaskAltIcon,
  PeopleAlt as PeopleAltIcon,
} from '@mui/icons-material';

export default function Dashboard() {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  // Dados para os gráficos e cards
  const lineChartData = [
    { x: 'Jan', y: 12 }, { x: 'Feb', y: 19 },
    { x: 'Mar', y: 3 }, { x: 'Apr', y: 5 },
    { x: 'May', y: 2 }, { x: 'Jun', y: 3 },
  ];

  const barChartData = [
    { x: 'Jan', y: 1000 }, { x: 'Feb', y: 2000 },
    { x: 'Mar', y: 1500 }, { x: 'Apr', y: 2500 },
    { x: 'May', y: 3000 }, { x: 'Jun', y: 4000 },
  ];

  const pieChartData = [
    { id: 'Produto A', value: 300 },
    { id: 'Produto B', value: 150 },
    { id: 'Produto C', value: 200 },
  ];

  const cardData = [
    { icon: <AccountBalanceIcon />, title: 'Saldo', value: 'R$ 50.000' },
    { icon: <TrendingUpIcon />, title: 'Receita', value: 'R$ 12.500' },
    { icon: <AttachMoneyIcon />, title: 'Despesas', value: 'R$ 8.000' },
    { icon: <EmailIcon />, title: 'E-mails Pendentes', value: '20' },
    { icon: <InsertChartIcon />, title: 'Projetos Ativos', value: '12' },
    { icon: <TaskAltIcon />, title: 'Tarefas Concluídas', value: '35' },
  ];

  const tasks = ['Finalizar relatório', 'Reunião com cliente', 'Enviar proposta', 'Agendar reunião interna'];
  const emails = ['Pedido de orçamento', 'Confirmação de reunião', 'Follow-up cliente'];

  return (
    <Box
      sx={{
        padding: 4,
        minHeight: '100vh',
        background: isDarkMode
          ? 'linear-gradient(135deg, #1c1c1c, #333)'
          : 'linear-gradient(135deg, #f0f4f8, #e8f0ff)',
        transition: 'background 0.3s',
        fontFamily: 'Poppins, sans-serif',
      }}
    >
      {/* Títulos e Introdução */}
      <Box sx={{ marginBottom: 4 }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
          Painel de Controle
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Bem-vindo ao seu dashboard personalizado! Aqui você encontra todas as métricas importantes.
        </Typography>
      </Box>

      {/* Seção de Gráficos */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <ChartCard title="Vendas Mensais">
            <LineChart
              series={[{ data: lineChartData, color: '#4a90e2' }]}
              width={300}
              height={200}
            />
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <ChartCard title="Desempenho Financeiro">
            <BarChart
              series={[{ data: barChartData, color: '#76c7c0' }]}
              width={300}
              height={200}
            />
          </ChartCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <ChartCard title="Distribuição de Produtos">
            <PieChart
              series={[{ data: pieChartData, colors: ['#ff6384', '#36a2eb', '#ffce56'] }]}
              width={300}
              height={200}
            />
          </ChartCard>
        </Grid>
      </Grid>

      {/* Cards Resumidos */}
      <Grid container spacing={3} sx={{ marginTop: 4 }}>
        {cardData.map((card, index) => (
          <Grid item xs={12} md={4} lg={2} key={index}>
            <Card
              elevation={2}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                padding: 2,
                background: isDarkMode ? '#424242' : '#ffffff',
                boxShadow: isDarkMode
                  ? '0 4px 10px rgba(0, 0, 0, 0.3)'
                  : '0 4px 10px rgba(0, 0, 0, 0.1)',
                transition: 'background 0.3s, box-shadow 0.3s',
              }}
            >
              <Avatar sx={{ backgroundColor: theme.palette.primary.main, marginBottom: 1 }}>
                {card.icon}
              </Avatar>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {card.title}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {card.value}
              </Typography>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Seção de Tarefas e E-mails */}
      <Grid container spacing={3} sx={{ marginTop: 4 }}>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              background: isDarkMode ? '#424242' : '#f5f5f5',
              transition: 'background 0.3s',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Tarefas Pendentes
            </Typography>
            <Divider />
            <List>
              {tasks.map((task, index) => (
                <ListItem key={index}>
                  <ListItemText primary={task} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              background: isDarkMode ? '#424242' : '#f5f5f5',
              transition: 'background 0.3s',
            }}
          >
            <Typography variant="h6" gutterBottom>
              E-mails Recebidos
            </Typography>
            <Divider />
            <List>
              {emails.map((email, index) => (
                <ListItem key={index}>
                  <ListItemText primary={email} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

function ChartCard({ title, children }) {
  const theme = useTheme();
  return (
    <Card
      elevation={3}
      sx={{
        padding: 3,
        backgroundColor: theme.palette.background.paper,
        transition: 'background-color 0.3s',
      }}
    >
      <CardHeader title={title} sx={{ paddingBottom: 0 }} />
      <CardContent>{children}</CardContent>
    </Card>
  );
}
