import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Toolbar,
  Divider,
  Tooltip,
  Popover,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  useTheme,
  Box,
} from '@mui/material';
import {
  Home as HomeIcon,
  AccountBalance as AccountBalanceIcon,
  Settings as SettingsIcon,
  Logout as LogoutIcon,
  Menu as MenuIcon,
  People as PeopleIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function Sidebar({ open, onToggle }) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const navigate = useNavigate();

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);
  const [cadastroAnchorEl, setCadastroAnchorEl] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  const handleLogoutDialogOpen = () => setLogoutDialogOpen(true);
  const handleLogoutDialogClose = () => setLogoutDialogOpen(false);

  const handleSubmenuClick = (event) =>
    setSubmenuAnchorEl(submenuAnchorEl ? null : event.currentTarget);
  const handleSubmenuClose = () => setSubmenuAnchorEl(null);

  const handleCadastroClick = (event) =>
    setCadastroAnchorEl(cadastroAnchorEl ? null : event.currentTarget);
  const handleCadastroClose = () => setCadastroAnchorEl(null);

  const submenuOpen = Boolean(submenuAnchorEl);
  const cadastroOpen = Boolean(cadastroAnchorEl);

  const sidebarItems = [
    { text: 'Início', icon: <HomeIcon />, route: '/Dashboard' },
    { text: 'Financeiro', icon: <AccountBalanceIcon />, route: '/modulo-financeiro' },
  ];

  const cadastroItems = [
    { label: 'Clientes', route: '/Clientes' },
    { label: 'Fornecedores', route: '/Fornecedores' },
    { label: 'Funcionários', route: '/Funcionarios' },
    { label: 'Produtos', route: '/Produtos' },
    { label: 'Projetos', route: '/Projetos' },
    { label: 'Serviços', route: '/Servicos' },
  ];

  const configuracoesItems = [
    { label: 'Certificado Digital', route: '/Certificados' },
    { label: 'E-mail', route: '/ConfiguracaoEmail' },
    { label: 'Empresas', route: '/Empresas' },
    { label: 'Integrações', route: '/Integracoes' },
    { label: 'Parâmetros', route: '/parametros' },
    { label: 'Usuários', route: '/Usuarios' },
    { label: 'WhatsApp', route: '/whatsapp' },
  ];

  return (
    <>
      <Drawer
        variant="permanent"
        anchor="left"
        open={open}
        sx={{
          width: open ? 260 : 80,
          transition: 'width 0.3s',
          '& .MuiDrawer-paper': {
            width: open ? 260 : 80,
            overflowX: 'hidden',
            background: isDarkMode
              ? 'linear-gradient(45deg, #2e3440, #4c566a)'
              : 'linear-gradient(45deg, #e5e9f0, #eceff4)',
            color: isDarkMode ? '#eceff4' : '#2e3440',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        }}
      >
        {/* Top Section */}
        <Box>
          <Toolbar
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 120,
              padding: '10px 0',
              transition: 'all 0.3s',
            }}
          >
            <IconButton onClick={onToggle} sx={{ color: '#4a90e2', mb: 2 }}>
              <MenuIcon />
            </IconButton>
            <img
              src="https://aabbengenharia.com.br/wp-content/uploads/2022/09/thumb.png"
              alt="Logo"
              style={{
                width: open ? '160px' : '50px',
                height: 'auto',
                transition: 'width 0.3s',
                marginTop: 10,
                marginBottom: 10,
                borderRadius: '5px',
              }}
            />
          </Toolbar>
          <Divider sx={{ backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' }} />

          <List>
            {sidebarItems.map((item, index) => (
              <Tooltip title={!open ? item.text : ''} placement="right" key={index}>
                <ListItem
                  button
                  onClick={() => navigate(item.route)}
                  sx={{
                    '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                    borderRadius: '10px',
                    margin: '8px',
                  }}
                >
                  <ListItemIcon sx={{ color: isDarkMode ? '#88c0d0' : '#5e81ac' }}>
                    {item.icon}
                  </ListItemIcon>
                  {open && <ListItemText primary={item.text} />}
                </ListItem>
              </Tooltip>
            ))}
            {/* Cadastro */}
            <Tooltip title={!open ? 'Cadastro' : ''} placement="right">
              <ListItem
                button
                onClick={handleCadastroClick}
                sx={{
                  '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                  borderRadius: '10px',
                  margin: '8px',
                }}
              >
                <ListItemIcon sx={{ color: isDarkMode ? '#88c0d0' : '#5e81ac' }}>
                  <PeopleIcon />
                </ListItemIcon>
                {open && <ListItemText primary="Cadastro" />}
              </ListItem>
            </Tooltip>
            <Popover
              open={cadastroOpen}
              anchorEl={cadastroAnchorEl}
              onClose={handleCadastroClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <List>
                {cadastroItems.map((item) => (
                  <ListItem
                    button
                    key={item.label}
                    onClick={() => navigate(item.route)}
                    sx={{
                      '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </ListItem>
                ))}
              </List>
            </Popover>
            {/* Configurações */}
            <Tooltip title={!open ? 'Configurações' : ''} placement="right">
              <ListItem
                button
                onClick={handleSubmenuClick}
                sx={{
                  '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                  borderRadius: '10px',
                  margin: '8px',
                }}
              >
                <ListItemIcon sx={{ color: isDarkMode ? '#88c0d0' : '#5e81ac' }}>
                  <SettingsIcon />
                </ListItemIcon>
                {open && <ListItemText primary="Configurações" />}
              </ListItem>
            </Tooltip>
            <Popover
              open={submenuOpen}
              anchorEl={submenuAnchorEl}
              onClose={handleSubmenuClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <List>
                {configuracoesItems.map((item) => (
                  <ListItem
                    button
                    key={item.label}
                    onClick={() => navigate(item.route)}
                    sx={{
                      '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                    }}
                  >
                    <ListItemText primary={item.label} />
                  </ListItem>
                ))}
              </List>
            </Popover>
          </List>
        </Box>

        {/* Footer Section */}
        <Box sx={{ padding: '16px', textAlign: 'center' }}>
          <Tooltip title="Sair" placement="right">
            <ListItem
              button
              onClick={handleLogoutDialogOpen}
              sx={{
                '&:hover': { backgroundColor: isDarkMode ? '#3b4252' : '#d8dee9' },
                borderRadius: '10px',
              }}
            >
              <ListItemIcon sx={{ color: isDarkMode ? '#d08770' : '#bf616a' }}>
                <LogoutIcon />
              </ListItemIcon>
              {open && <ListItemText primary="Sair" />}
            </ListItem>
          </Tooltip>
        </Box>
      </Drawer>

      {/* Logout Dialog */}
      <Dialog open={logoutDialogOpen} onClose={handleLogoutDialogClose}>
        <DialogTitle>Deseja realmente sair?</DialogTitle>
        <DialogActions>
          <Button onClick={handleLogoutDialogClose}>Cancelar</Button>
          <Button onClick={handleLogout} color="error">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
